import Logo from './lap-logo.png';
import { Config } from '../../interfaces/config.interface';

const config: Config = {
  logo: Logo,
  title: 'Actividades de voluntariado',
  name: 'Lima airport partners',
};

export default config;
